<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="350"
  >
    <v-card>
      <v-card-title>Syncing to-dos with Teamwork</v-card-title>
      <v-card-text>
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="10"
          :value="progress"
          color="primary"
          class="d-flex mx-auto my-3"
        >
          {{ progress }}%
        </v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingDialogSyncProgress',
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    syncedItems: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      dialog: true
    }
  },
  computed: {
    progress () {
      const percentage = Math.floor((this.syncedItems * 100) / this.totalItems)
      return Math.min(percentage, 100)
    }
  }
}
</script>
