<template>
  <v-menu
    v-if="!isScribe && !isFacilitator && !disabled"
    offset-y
    close-on-content-click
  >
    <template v-slot:activator="{ on }">
      <v-chip v-if="isAway" v-on="on" small>
        Away
        <v-icon right x-small>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip v-else v-on="on" color="success" dark small>
        Present
        <v-icon right x-small>mdi-chevron-down</v-icon>
      </v-chip>
    </template>
    <v-list dense>
      <v-list-item @click="changeStatus('scribe')">
        <v-list-item-title>Is the Scribe</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canHaveFacilitator" @click="changeStatus('facilitator')">
        <v-list-item-title>Is the Facilitator</v-list-item-title>
      </v-list-item>
      <v-list-item v-show="isAway" @click="changeStatus('present')">
        <v-list-item-title>Present</v-list-item-title>
      </v-list-item>
      <v-list-item v-show="!isAway" @click="changeStatus('away')">
        <v-list-item-title>Away</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <div v-else>
    <v-chip v-if="isScribe" color="grey darken-3" dark small>Scribe</v-chip>
    <v-chip v-else-if="isFacilitator" color="grey darken-3" dark small>Facilitator</v-chip>
    <v-chip v-else-if="isAway" dark small>Away</v-chip>
    <v-chip v-else color="success" dark small>Present</v-chip>
  </div>
</template>

<script>
export default {
  name: 'MeetingAttendeeStatus',
  props: {
    attendee: {
      type: Object,
      default: () => ({})
    },
    isScribe: {
      type: Boolean,
      default: false
    },
    isFacilitator: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    canHaveFacilitator: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isAway () {
      return this.attendee.isPresent === false
    }
  },
  methods: {
    changeStatus (status) {
      this.$emit('change-status', status)
    }
  }
}
</script>
