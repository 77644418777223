var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"eos-meeting__toolbar",attrs:{"dense":"","flat":""}},[(_vm.name)?_c('v-toolbar-title',{staticClass:"mr-2",class:{ 'cursor-pointer': _vm.allowUpdate },on:{"click":function($event){return _vm.$emit('edit-name')}}},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),(_vm.previousId || _vm.nextId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"icon":"","x-small":"","depressed":"","color":"#777","disabled":!_vm.previousId,"to":{
          name: 'level10',
          params: { level10Id: _vm.previousId }
        }}},on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}],null,false,1346761954)},[_c('span',[_vm._v("Previous")])]):_vm._e(),_c('AppDatePicker',{attrs:{"title":"Meeting Date","date":_vm.date,"disabled":!_vm.allowUpdate},on:{"change":function($event){return _vm.$emit('change-date', $event)}}}),(_vm.nextId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":"","depressed":"","color":"#777","to":{
          name: 'level10',
          params: { level10Id: _vm.nextId }
        }}},on),[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}],null,false,1451549164)},[_c('span',[_vm._v("Next")])]):_vm._e(),_c('v-spacer'),_c('AppVisibilityBtn',{attrs:{"entity":"weekly meeting","visibility":_vm.visibility},on:{"click":function($event){return _vm.$emit('toggle-privacy')}}}),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[(_vm.allowUpdate)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('edit-name')}}},[_c('v-list-item-title',[_vm._v("Rename")])],1):_vm._e(),(_vm.allowDelete)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('import-export')}}},[_c('v-list-item-title',[_vm._v("Import/Export Data")])],1),(_vm.allowSync)?_c('AppSyncOptions',{attrs:{"tw-sync-options":_vm.twSyncOptions},on:{"show-sync-options":function($event){return _vm.$emit('show-sync-options')},"initialize-tw-oauth-process":function($event){return _vm.$emit('initialize-tw-oauth-process')},"save-sync-options":function($event){return _vm.$emit('save-sync-options', $event)}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }