<template>
  <v-container fluid class="px-4 py-2 eos-meeting__timer">
    <v-layout v-if="showStartBtn" row>
      <v-flex text-md-center>
        <v-btn
          depressed
          color="primary"
          class="mt-5 mb-5"
          @click="$emit('start-meeting')"
        >
          <v-icon class="pr-3">mdi-timer</v-icon>
          Start Meeting
        </v-btn>
      </v-flex>
    </v-layout>
    <template v-else>
      <v-layout row>
        <v-flex text-md-center>
          <v-btn text :color="btnColor">
            <v-icon v-if="progress >= 98" class="pr-3">mdi-clock-alert</v-icon>
            {{ timeSpend }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex>
          <v-progress-linear :value="progress" />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex text-md-center>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="$emit('go-to-previous-step')">
                <v-icon>mdi-skip-previous</v-icon>
              </v-btn>
            </template>
            <span>Previous</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="$emit('toggle-meeting-status')">
                <v-icon v-if="isRunning">mdi-pause</v-icon>
                <v-icon v-else>mdi-play</v-icon>
              </v-btn>
            </template>
            <span>{{ isRunning ? 'Pause' : 'Play' }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="$emit('go-to-next-step')">
                <v-icon>mdi-skip-next</v-icon>
              </v-btn>
            </template>
            <span>Next</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </template>
  </v-container>
</template>

<script>
export default {
  name: 'MeetingTimer',
  props: {
    showStartBtn: {
      type: Boolean,
      default: false
    },
    isRunning: {
      type: Boolean,
      default: false
    },
    timeSpend: {
      type: String,
      default: '00:00:00'
    },
    progress: {
      type: [String, Number],
      default: 0
    },
    btnColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-meeting__timer {
  border-bottom: 1px solid #eee;
}
</style>
