<template>
  <v-toolbar dense flat class="eos-meeting-step__toolbar">
    <v-app-bar-nav-icon v-if="withHamburgerIcon" @click="$emit('toggle-drawer')" />
    <v-toolbar-title v-html="title" class="pl-1" />

    <slot name="guide"></slot>

    <v-spacer />

    <v-btn
      v-if="displayTimeSpend"
      text
      :color="btnColor"
    >
      <v-icon class="pr-3">mdi-clock</v-icon>
      {{ timeSpend }}
    </v-btn>

    <AppSyncBtn
      v-if="canSync"
      text="Sync todos"
      :syncing="syncing"
      @sync="$emit('sync-todos')"
    />

    <AppUserPermissionList
      v-if="canManageAttendee"
      ref="permissions"
      entity="Weekly Meeting"
      :entity-name="meetingName"
      :visibility="visibility"
      :domains="domains"
      :users="attendees"
      :logged-in-user="loggedInUser"
      :tw-sync-options="twSyncOptions"
      :disable-delete-for-emails="[scribe, facilitator]"
      :sync-handles-with-teamwork="canSync"
      logged-in-user-can-leave
      @make-private="$emit('make-private')"
      @update-permissions="$emit('update-permissions', $event)"
      @set-message="$emit('set-message', $event)"
    >
      <template v-slot:actions="{ user }">
        <v-list-item-action>
          <MeetingAttendeeStatus
            :attendee="user"
            :is-scribe="user.email === scribe"
            :is-facilitator="user.email === facilitator"
            :can-have-facilitator="canHaveFacilitator"
            @change-status="$emit('update-attendee-status', { email: user.email, status: $event })"
          />
        </v-list-item-action>
      </template>

      <v-tooltip slot="footer-right" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="$emit('choose-random-scribe')">
            <v-icon>mdi-swap-horizontal</v-icon>
          </v-btn>
        </template>
        <span>Choose a random scibe</span>
      </v-tooltip>
    </AppUserPermissionList>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          :disabled="selectedStepIndex === 0"
          @click="$emit('go-to-previous-step')"
        >
          <v-icon>mdi-skip-previous</v-icon>
        </v-btn>
      </template>
      <span>Previous</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          :disabled="selectedStepIndex === 7"
          @click="$emit('go-to-next-step')"
        >
          <v-icon>mdi-skip-next</v-icon>
        </v-btn>
      </template>
      <span>Next</span>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
import AppUserPermissionList from '@/components/shared/permissions/AppUserPermissionList'
import AppSyncBtn from '@/components/shared/sync/AppSyncBtn'
import MeetingAttendeeStatus from '@/components/meeting/MeetingAttendeeStatus'

export default {
  name: 'MeetingStepsToolbar',
  components: {
    AppUserPermissionList,
    AppSyncBtn,
    MeetingAttendeeStatus
  },
  props: {
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    withHamburgerIcon: {
      type: Boolean,
      default: false
    },
    meetingName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    displayTimeSpend: {
      type: Boolean,
      default: false
    },
    timeSpend: {
      type: String,
      default: '00:00:00'
    },
    btnColor: {
      type: String,
      default: ''
    },
    selectedStepIndex: {
      type: [Number, String],
      default: 0
    },
    canManageAttendee: {
      type: Boolean,
      default: false
    },
    canSync: {
      type: Boolean,
      default: false
    },
    visibility: {
      type: String,
      default: 'public'
    },
    scribe: {
      type: String,
      default: ''
    },
    facilitator: {
      type: String,
      default: ''
    },
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    domains: {
      type: Array,
      default: () => ([])
    },
    syncing: {
      type: Boolean,
      default: false
    },
    canHaveFacilitator: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    managePermissions () {
      this.$refs.permissions.openDialogUsersList()
    },
    openDialogAddMember () {
      this.$refs.permissions.showDialogAddEditUser()
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-meeting-step__toolbar {
  border-bottom:1px solid #eee;
}
</style>
