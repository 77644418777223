var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"eos-meeting-step__toolbar",attrs:{"dense":"","flat":""}},[(_vm.withHamburgerIcon)?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.$emit('toggle-drawer')}}}):_vm._e(),_c('v-toolbar-title',{staticClass:"pl-1",domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._t("guide"),_c('v-spacer'),(_vm.displayTimeSpend)?_c('v-btn',{attrs:{"text":"","color":_vm.btnColor}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.timeSpend)+" ")],1):_vm._e(),(_vm.canSync)?_c('AppSyncBtn',{attrs:{"text":"Sync todos","syncing":_vm.syncing},on:{"sync":function($event){return _vm.$emit('sync-todos')}}}):_vm._e(),(_vm.canManageAttendee)?_c('AppUserPermissionList',{ref:"permissions",attrs:{"entity":"Weekly Meeting","entity-name":_vm.meetingName,"visibility":_vm.visibility,"domains":_vm.domains,"users":_vm.attendees,"logged-in-user":_vm.loggedInUser,"tw-sync-options":_vm.twSyncOptions,"disable-delete-for-emails":[_vm.scribe, _vm.facilitator],"sync-handles-with-teamwork":_vm.canSync,"logged-in-user-can-leave":""},on:{"make-private":function($event){return _vm.$emit('make-private')},"update-permissions":function($event){return _vm.$emit('update-permissions', $event)},"set-message":function($event){return _vm.$emit('set-message', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var user = ref.user;
return [_c('v-list-item-action',[_c('MeetingAttendeeStatus',{attrs:{"attendee":user,"is-scribe":user.email === _vm.scribe,"is-facilitator":user.email === _vm.facilitator,"can-have-facilitator":_vm.canHaveFacilitator},on:{"change-status":function($event){return _vm.$emit('update-attendee-status', { email: user.email, status: $event })}}})],1)]}}],null,false,3011974605)},[_c('v-tooltip',{attrs:{"slot":"footer-right","bottom":""},slot:"footer-right",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('choose-random-scribe')}}},on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}],null,false,1793058777)},[_c('span',[_vm._v("Choose a random scibe")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.selectedStepIndex === 0},on:{"click":function($event){return _vm.$emit('go-to-previous-step')}}},on),[_c('v-icon',[_vm._v("mdi-skip-previous")])],1)]}}])},[_c('span',[_vm._v("Previous")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.selectedStepIndex === 7},on:{"click":function($event){return _vm.$emit('go-to-next-step')}}},on),[_c('v-icon',[_vm._v("mdi-skip-next")])],1)]}}])},[_c('span',[_vm._v("Next")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }