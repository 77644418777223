<template>
  <v-dialog v-model="dialog" persistent max-width="418px">
    <v-card>
      <form @submit.prevent="save">
        <v-card-title>
          <span class="headline">What's your team name?</span>
        </v-card-title>
        <v-card-text>
          <p>
            Enter a team name like "Leadership Team" or "Marketing".
            You can change this later.
          </p>
          <v-text-field
            v-model.trim="meetingName"
            :label="label"
            autofocus
            outlined
            hide-details
            class="pt-5"
          />
        </v-card-text>
        <v-card-actions class="px-4">
          <v-spacer />
          <v-btn text @click="closeDialog">Cancel</v-btn>
          <v-btn color="success" :loading="showLoader" @click="save">Save</v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingDialogEditName',
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: true,
      meetingName: this.name
    }
  },
  methods: {
    save () {
      this.$emit('save', this.meetingName)
    },
    closeDialog () {
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
