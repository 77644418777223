<template>
  <v-dialog :value="value" persistent max-width="600px">
    <v-form @submit.prevent="save">
      <v-card flat color="white">
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="guide" class="mt-3" :label="label" hide-details outlined />
        </v-card-text>
        <v-card-actions class="px-4">
          <p>
            Tip: You can use
            <a
              href="https://www.markdownguide.org/cheat-sheet/"
              target="_blank"
            >markdown</a>.
          </p>
          <v-spacer />
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn type="submit" color="success" :loading="showLoader">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'MeetingDialogMarkdownEditor',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Edit'
    },
    label: {
      type: String,
      default: ''
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      guide: ''
    }
  },
  watch: {
    text: {
      immediate: true,
      handler (txt) {
        this.guide = txt || ''
      }
    },
    value (open) {
      if (open) this.guide = this.text
    }
  },
  methods: {
    save () {
      this.$emit('save', this.guide)
    },
    cancel () {
      this.$emit('input')
      this.$emit('cancel')
    }
  }
}
</script>
