<template>
  <v-container pa-0 ma-0 grid-list-md fluid fill-height>
    <v-layout pa-0 ma-0 fill-height>
      <v-flex
        v-if="showDrawer"
        pa-0
        ma-0
        class="eos-meeting__drawer"
        :style="{
          minWidth: `${drawerWidth}px`,
          maxWidth: `${drawerWidth}px`,
        }"
      >
        <MeetingTimer
          :show-start-btn="l10Data.elapsedTimeInSecs <= 0"
          :is-running="l10Data.meetingIsRunning"
          :time-spend="displayTime"
          :progress="totalTimeProgress"
          :btn-color="colorForPercent(totalTimeProgress)"
          @start-meeting="startMeeting"
          @go-to-previous-step="goToPrevStep"
          @go-to-next-step="goToNextStep"
          @toggle-meeting-status="toggleMeetingStatus"
        />

        <MeetingStepper
          :current-step="selectedStageNoAsStep"
          :progress="currentStageProgress"
          :color="colorForPercent(currentStageProgress)"
          :steps="visibleStages"
          @select-step="changeStep"
        />
      </v-flex>

      <v-flex pa-0 ma-0 :style="{ width: contentWidth }">
        <MeetingSummary
          v-if="showMeetingHasEndedCover"
          :todos="summaryTodos"
          :attendees="l10Data.attendees"
          :avg-rating="averageRating"
          :hide-rating="hiddenSections.includes('meetingRating')"
          :has-next-meeting="!!(l10Meta.seguence && l10Meta.seguence.nextId)"
          :show-loader="settingUpNextMeeting"
          @view-details="showMeetingDetailsEvenWhenEnded = true"
          @setup-next-meeting="setupNextMeeting"
        />

        <template v-else>
          <MeetingStepToolbar
            ref="toolbar"
            :tw-sync-options="twSyncOptions"
            :meeting-name="l10Meta.name"
            :title="selectedStage.longName"
            :visibility="l10Meta.visibility"
            :domains="l10Meta.permissions.domains"
            :scribe="l10Data.scribe"
            :facilitator="l10Data.facilitator"
            :attendees="l10Data.attendees"
            :logged-in-user="loggedInUserInfo"
            :with-hamburger-icon="canHaveDrawer"
            :selected-step-index="selectedStageNo"
            :display-time-spend="!showDrawer"
            :time-spend="displayTime"
            :btn-color="colorForPercent(totalTimeProgress)"
            :can-manage-attendee="!meetingHasEnded"
            :can-have-facilitator="l10Data.plan.hasFacilitator"
            :can-sync="canSync"
            :syncing="syncingSilent"
            @sync-todos="twoWaySyncTodos"
            @toggle-drawer="wantDrawer = !wantDrawer"
            @go-to-previous-step="goToPrevStep"
            @go-to-next-step="goToNextStep"
            @make-private="$emit('toggle-privacy')"
            @update-permissions="$emit('update-permissions', $event)"
            @choose-random-scribe="chooseRandomScribe"
            @update-attendee-status="updateAttendeeStatus"
            @set-message="setMessage"
          >
            <MeetingStepGuide
              slot="guide"
              ref="stepGuide"
              :text="stageGuideText"
              :editable="!meetingHasEnded"
              :is-updating-guide="isUpdatingStepGuide"
              @save="updateStepGuide"
            />
          </MeetingStepToolbar>

          <div class="d-block eos-meeting__content" :class="selectedStage.dataField">
            <div
              v-if="meetingHasEnded"
              class="d-flex justify-center pa-4 eos-meeting__quote--completed"
            >
              <div class="align-self-center pr-1">This meeting has ended</div>
              <v-btn text @click="showMeetingDetailsEvenWhenEnded = false">Return to Summary</v-btn>
            </div>

            <MeetingStepPlanner
              v-show="selectedStage.dataField === 'plan'"
              ref="stepMeetingPlanner"
              :logged-in-user="loggedInUserInfo"
              :stages="visibleStages"
              :optional-stages="optionalStages"
              :meeting-id="level10Id"
              :plan="l10Data.plan"
              :is-updating-plan="isUpdatingMeetingPlan"
              :scribe="l10Data.scribe"
              :facilitator="l10Data.facilitator"
              :attendees="l10Data.attendees"
              :allow-sync="allowSync"
              :sync-options="l10Meta.twSyncOptions"
              :syncing="syncingSilent"
              :unsynced-item-counts="unSyncedTasks"
              :is-editable="!meetingHasEnded"
              :total-time-in-minutes="totalTimeInMinutes"
              @data-updated="updateMeetingPlanData"
              @choose-random-scribe="chooseRandomScribe"
              @choose-random-facilitator="chooseRandomFacilitator"
              @manage-attendees="openDialogAttendeeList"
              @update-attendee-status="updateAttendeeStatus"
              @sync-with-teamwork="syncWithTeamwork"
              @sync-todos="twoWaySyncTodos"
              @set-message="setMessage"
              @add-member="addMember"
              @update-timing="updateMeetingSectionTimings"
              @toggle-section="toggleMeetingSection"
              @toggle-facilitator="toggleFacilitator"
            />

            <template v-if="selectedStage.dataField === 'segue'">
              <div
                class="mt-3 mx-4 mb-5"
              >
                <div class="subtitle-1">Meeting notes</div>
                <ul>
                  <li>{{ l10Data.scribe | nameByEmail(l10Data.attendees) }} is the scribe</li>
                  <li v-if="l10Data.facilitator">{{ l10Data.facilitator | nameByEmail(l10Data.attendees) }} is the facilitator</li>
                </ul>
              </div>

              <MeetingStepSegue
                :logged-in-user="loggedInUserInfo"
                :level10-id="level10Id"
                :initial-segue-data="l10Data.segue"
                :attendees="l10Data.attendees"
                :drag-options="dragOptions"
                :is-editable="!meetingHasEnded"
                @data-updated="updateSegueData"
                @manage-attendees="openDialogAttendeeList"
                @set-message="setMessage"
              />
            </template>

            <MeetingStepScorecard
              v-if="selectedStage.dataField === 'scorecard'"
              :initial-scorecard-data="l10Data.scorecard"
              :is-editable="!meetingHasEnded"
              @data-updated="updateScrorecardData"
              @add-ids-issue="addIDSIssue"
              @set-message="setMessage"
            />

            <MeetingStepRocks
              v-if="selectedStage.dataField === 'rocks'"
              :logged-in-user="loggedInUserInfo"
              :initial-rocks-data="l10Data.rocks"
              :attendees="l10Data.attendees"
              :drag-options="dragOptions"
              :is-editable="!meetingHasEnded"
              :level10-id="level10Id"
              @data-updated="updateRocksData"
              @manage-attendees="openDialogAttendeeList"
              @add-ids-issue="addIDSIssue"
              @set-message="setMessage"
            />

            <MeetingStepHeadlines
              v-if="selectedStage.dataField === 'headlines'"
              :logged-in-user="loggedInUserInfo"
              :initial-headlines-data="l10Data.headlines"
              :attendees="l10Data.attendees"
              :drag-options="dragOptions"
              :is-editable="!meetingHasEnded"
              @data-updated="updatedHeadlinesData"
              @manage-attendees="openDialogAttendeeList"
              @add-ids-issue="addIDSIssue"
              @set-message="setMessage"
            />

            <MeetingStepTodos
              v-if="selectedStage.dataField === 'todos'"
              :logged-in-user="loggedInUserInfo"
              :initial-todos-data="l10Data.todos"
              :attendees="l10Data.attendees"
              :drag-options="dragOptions"
              :is-editable="!meetingHasEnded"
              :tw-projects="twProjects"
              :tw-tasklists="twTasklists"
              :tw-sync-options="twSyncOptions"
              :meeting-has-started="meetingHasStarted"
              @data-updated="updatedTodosData"
              @manage-attendees="openDialogAttendeeList"
              @add-ids-issue="addIDSIssue"
              @delete-todo="deleteTodo"
              @go-to-tw-task="goToTWTask"
              @set-message="setMessage"
              @refresh-tasklist="$emit('refresh-tasklist', $event)"
            />

            <MeetingStepIDS
              v-if="selectedStage.dataField === 'IDS'"
              :logged-in-user="loggedInUserInfo"
              :initial-ids-data="l10Data.IDS"
              :attendees="l10Data.attendees"
              :scribe="l10Data.scribe"
              :facilitator="l10Data.facilitator"
              :is-editable="!meetingHasEnded"
              :tw-projects="twProjects"
              :tw-tasklists="twTasklists"
              :tw-sync-options="twSyncOptions"
              :can-vote="!hiddenSections.includes('votingTool')"
              @data-updated="updateIDSData"
              @manage-attendees="openDialogAttendeeList"
              @delete-todo="deleteTodo"
              @set-message="setMessage"
            />

            <div v-if="selectedStage.dataField === 'conclude'">
              <MeetingStepConcludeTodos
                :logged-in-user="loggedInUserInfo"
                :initial-ids-issues="l10Data.IDS.issues"
                :carried-over-todos="carriedOverTodos"
                :extra-todos="extraTodos"
                :attendees="l10Data.attendees"
                :is-editable="!meetingHasEnded"
                :tw-projects="twProjects"
                :tw-tasklists="twTasklists"
                :tw-sync-options="twSyncOptions"
                @data-updated="updateSummaryTodos"
                @manage-attendees="openDialogAttendeeList"
                @delete-todo="deleteTodo"
                @go-to-tw-task="goToTWTask"
                @set-message="setMessage"
                @show-sync-options="$emit('show-sync-options')"
              />

              <MeetingStepConcludeNotes
                ref="meetinStepConcludeNotes"
                :text="l10Data.conclude.messageToShare"
                :is-editable="!meetingHasEnded"
                :is-updating-notes="updatingNotesToShare"
                @update="updateNotesToShare"
              />

              <MeetingStepConcludeRatings
                v-show="!hiddenSections.includes('meetingRating')"
                :logged-in-user="loggedInUserInfo"
                :initial-conclude-data="l10Data.conclude"
                :attendees="l10Data.attendees"
                :scribe="l10Data.scribe"
                :facilitator="l10Data.facilitator"
                :avg-rating="averageRating"
                :is-editable="!meetingHasEnded"
                @data-updated="updateConcludeData"
                @manage-attendees="openDialogAttendeeList"
                @set-message="setMessage"
              />

              <v-divider class="mt-5" />
              <v-btn v-if="!meetingHasEnded" large class="ma-5" @click="beforeMeetingEnd">
                <v-icon left color="error">mdi-stop-circle</v-icon>
                End Meeting
              </v-btn>
            </div>
          </div>
        </template>
      </v-flex>
    </v-layout>

    <MeetingDialogSyncProgress
      v-if="syncingItems && totalItemsToSync > 0"
      :total-items="totalItemsToSync"
      :synced-items="totalItemsSynced"
    />

    <v-dialog
      v-model="showDialogSyncWithTeamwork"
      persistent
      width="418"
    >
      <v-card>
        <v-card-title>
          <template v-if="warnToChooseOptionsBeforeMeetingEnd">Warning</template>
          <template v-else>Do you know?</template>
        </v-card-title>
        <v-card-text class="pb-2 secondary--text">
          <template v-if="warnToChooseOptionsBeforeMeetingEnd">
            Please select default Project and Tasklist to sync todos with
            <AppTeamworkBranding />
          </template>
          <template v-else>
            <ul>
              <li class="mb-2">
                You can sync your todos with <AppTeamworkBranding /> tasks. Teamwork is an unrivalled project management helps you get organized, track every billable minute and ensure projects are delivered on-time and on-budget. Together with EOS Free Tools, it's a powerful combination to get things done.
              </li>
              <li class="mb-2">
                If you don't have a <AppTeamworkBranding /> account yet, you can sign up for free from <a href="https://www.teamwork.com/signup?from=eosfreetools" class="text-decoration-none">here</a>
              </li>
            </ul>
            <div class="d-flex justify-center mt-5">
              <iframe width="100%" height="208" src="https://www.youtube.com/embed/wbwq76Py_lU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </template>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn text color="error" @click="endMeeting">End Meeting</v-btn>
          <v-btn color="success" @click="syncWithTeamwork">
            <template v-if="twSyncOptions.accessToken">Select sync options</template>
            <template v-else>Sync with Teamwork</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AppDialogConfirmDelete
      v-if="showDialogEndingFutureMeeting"
      title="Attention!"
      message="You're trying to end a meeting that's set in the future. Are you sure you want to proceed?"
      @confirm="beforeMeetingEnd"
      @cancel="showDialogEndingFutureMeeting = false"
    />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

import DataManager from '@/helpers/dataManager'
import MEETING_STATUSES from '@/enums/meetingStatuses'

/* Common components */
import AppTeamworkBranding from '@/components/shared/misc/AppTeamworkBranding'
import MeetingTimer from '@/components/meeting/drawer/MeetingTimer'
import MeetingStepper from '@/components/meeting/drawer/MeetingStepper'
import MeetingStepToolbar from '@/components/meeting/steps/MeetingStepToolbar'
import MeetingStepGuide from '@/components/meeting/steps/MeetingStepGuide'
import MeetingDialogSyncProgress from '@/components/meeting/dialogs/MeetingDialogSyncProgress'

import DEFAULT_USER_PERMISSIONS from '@/enums/defaultUserPermissions'

/* Load chunks on demand */
const MeetingSummary = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/MeetingSummary.vue')
const MeetingStepPlanner = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepPlanner.vue')
const MeetingStepSegue = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepSegue.vue')
const MeetingStepScorecard = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepScorecard.vue')
const MeetingStepRocks = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepRocks.vue')
const MeetingStepHeadlines = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepHeadlines.vue')
const MeetingStepTodos = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepTodos.vue')
const MeetingStepIDS = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepIDS.vue')
const MeetingStepConcludeRatings = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepConcludeRatings.vue')
const MeetingStepConcludeTodos = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepConcludeTodos.vue')
const MeetingStepConcludeNotes = () => import(/* webpackChunkName: "meeting-components" */ '@/components/meeting/steps/MeetingStepConcludeNotes.vue')

export default {
  name: 'EOSLevel10',
  components: {
    AppTeamworkBranding,
    MeetingTimer,
    MeetingStepper,
    MeetingStepToolbar,
    MeetingStepGuide,
    MeetingDialogSyncProgress,
    MeetingStepPlanner,
    MeetingStepSegue,
    MeetingStepScorecard,
    MeetingStepRocks,
    MeetingStepHeadlines,
    MeetingStepTodos,
    MeetingStepIDS,
    MeetingStepConcludeRatings,
    MeetingStepConcludeTodos,
    MeetingStepConcludeNotes,
    MeetingSummary
  },
  props: {
    level10Id: {
      type: String,
      required: true
    },
    initialL10Meta: {
      type: Object,
      default: () => ({})
    },
    initialL10Data: {
      type: Object,
      default: () => ({})
    },
    loggedInUserInfo: {
      type: Object,
      default: () => ({})
    },
    anonymousUser: {
      type: Object,
      default: () => ({})
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    twProjects: {
      type: Array,
      default: () => ([])
    },
    twTasklists: {
      type: Array,
      default: () => ([])
    },
    allowSync: {
      type: Boolean,
      default: false
    },
    lastUpdatedMinsBefore: {
      type: Number,
      default: 0
    }
  },
  data () {
    const dataManager = new DataManager()

    return {
      dataManager: dataManager,
      meetingPulseTimer: null,
      l10Meta: {
        visibility: 'private',
        permissions: {
          domains: []
        }
      },
      l10Data: {
        plan: {
          imageURL: '',
          notes: '',
          hiddenSections: [],
          carriedOver: true,
          hasFacilitator: true
        },
        attendees: [],
        conclude: {
          meetingRatings: [],
          extraTodos: [],
          messageToShare: ''
        },
        IDS: {
          issues: []
        },
        headlines: {
          headlines: []
        },
        segue: {
          notes: []
        },
        scorecard: dataManager.getNewObjectData('scorecards'),
        todos: {
          todos: []
        },
        rocks: {
          rocks: []
        },
        meetingStatus: MEETING_STATUSES.PLANNED,
        elapsedTimeInSecs: 0,
        scribe: ''
      },
      selectedStageNo: 0,
      defaultStages: [
        {
          shortName: 'Meeting Planner',
          longName: 'Meeting Planner',
          dataField: 'plan',
          timeInMins: 0,
          sideSubText: 'All meeting information',
          defaultGuideText: 'Plan and prepare your meeting by assigning a scribe and facilitator, managing your meeting attendees and syncing your todos. You can add any important information in the image and notes section below.'
        },
        {
          shortName: 'Segue',
          longName: 'Segue',
          dataField: 'segue',
          timeInMins: 5,
          sideSubText: 'Start Positive',
          defaultGuideText: 'Start the meeting on time with a quick sharing of your best piece of personal news and business news since your last team meeting. Go around the table and have each person share.'
        },
        {
          shortName: 'Scorecard',
          longName: 'Scorecard',
          dataField: 'scorecard',
          timeInMins: 5,
          sideSubText: 'Speed Reporting',
          defaultGuideText: 'Smoke out any issues that surfaced since you last met. Simply report the numbers and state if they are on track or off track.',
          canBeHidden: true
        },
        {
          shortName: 'Key Priorities',
          longName: 'Key Priorities',
          dataField: 'rocks',
          timeInMins: 5,
          sideSubText: 'Speed Reporting',
          defaultGuideText: 'Smoke out any issues standing in the way of completing your quarterly priorities. Key Priorities are the three to seven most important objectives for the company and each person for the quarter. Reporting on the Key Priorities keeps everyone apprised of your progress toward achieving your quarterly priorities. It also helps people focus on the ultimate purpose of the meeting—to work toward achieving those priorities. Go through the list of Key Priorities and ask each Key Priority owner to simply state if they are On Track, Off Track or Complete.',
          canBeHidden: true
        },
        {
          shortName: 'Headlines',
          longName: 'Headlines',
          dataField: 'headlines',
          timeInMins: 5,
          sideSubText: 'Speed Reporting',
          defaultGuideText: 'Share any customer successes or concerns that the team should know about.  Next, share any good news (or any bad news) about individual employees. This is a great place to recognize employees who have exhibited (or breached) the company’s core values. Keep their headlines to one sentence.',
          canBeHidden: true
        },
        {
          shortName: 'To-dos Review',
          longName: 'To-dos Review',
          dataField: 'todos',
          timeInMins: 5,
          sideSubText: 'Speed Reporting',
          defaultGuideText: 'Review your To Do List to make sure that every action item from last week’s meeting was accomplished. If something hasn’t been completed, drop it to the Issues List to decide how to remove any obstacles standing in the way.'
        },
        {
          shortName: 'Solving Issues',
          longName: 'Solving Issues',
          dataField: 'IDS',
          timeInMins: 60,
          sideSubText: 'Solving Mode',
          defaultGuideText: 'This is where issues are solved and To Dos are created. Give everyone one last chance to think about and add any issues to the Issues List. Include any issues from last week’s meeting that you couldn’t get to. In less than 30 seconds, the Facilitator should identify the three most important issues by picking Numbers 1, 2, and 3. Never start at the top and work your way down. It’s vital to solve the most critical issues first, because you don’t want to spend the bulk of your time discussing less important matters.'
        },
        {
          shortName: 'Conclude',
          longName: 'Conclude',
          dataField: 'conclude',
          timeInMins: 5,
          sideSubText: 'Good Wrap Up',
          // eslint-disable-next-line
          defaultGuideText: `1. Recap the To Dos and make sure each one has an owner who will be accountable for it.
  2. Determine if there are any cascading messages to share with others in the organization, and add these items to the To Do List to ensure completion.
  3. Rate the meeting on a scale of 1 to 10. A 10 doesn’t mean you had a perfect meeting, or a conflict-free meeting. It means your team accomplished what it needed to, you got what you needed from the meeting, and the process was followed well. You should always be averaging an 8 or better. If someone rates the meeting 8 or below, ask what would have made the meeting better.
  4. End the meeting ON TIME.`
        }
      ],
      loggedInUserAsAttendee: {
        displayName: this.loggedInUserInfo.displayName,
        photoURL: this.loggedInUserInfo.photoURL,
        email: this.loggedInUserInfo.email,
        isPresent: true
      },
      windowWidth: 0,
      wantDrawer: true,
      drawerWidth: 250,
      settingUpNextMeeting: false,
      showMeetingDetailsEvenWhenEnded: false,
      isUpdatingStepGuide: false,
      isUpdatingMeetingPlan: false,
      dragOptions: {
        disabled: false,
        ghostClass: 'ghost'
      },
      showDialogSyncWithTeamwork: false,
      syncingItems: false,
      syncingSilent: false,
      totalItemsToSync: 0,
      totalItemsSynced: 0,
      updatingNotesToShare: false,
      showDialogEndingFutureMeeting: false
    }
  },
  computed: {
    hiddenSections () {
      return this.l10Data.plan.hiddenSections || []
    },
    stages () {
      const stages = []
      const hiddenSections = this.hiddenSections
      let stageTimeSoFarMins = 0
      for (let stageNo = 0; stageNo < this.defaultStages.length; stageNo++) {
        const stage = this.defaultStages[stageNo]
        const isVisible = !hiddenSections.includes(stage.dataField)
        stage.timeInMins = parseInt(this.l10Data.plan[stage.dataField]) || stage.timeInMins
        stage.startTimeMins = stageTimeSoFarMins
        stageTimeSoFarMins += isVisible ? stage.timeInMins : 0 // consider 0 if section is hidden
        stage.endTimeMins = stageTimeSoFarMins
        stage.isVisible = isVisible

        stages.push(stage)
      }
      return stages
    },
    optionalStages () {
      const mainSteps = this.stages.filter(stage => stage.canBeHidden === true)
      const subSteps = [{
        shortName: 'Voting Tool',
        dataField: 'votingTool',
        isVisible: !this.hiddenSections.includes('votingTool')
      },
      {
        shortName: 'Meeting Rating',
        dataField: 'meetingRating',
        isVisible: !this.hiddenSections.includes('meetingRating')
      }]

      return [...mainSteps, ...subSteps]
    },
    visibleStages () {
      return this.stages.filter(stage => !this.hiddenSections.includes(stage.dataField))
    },
    totalTimeInMinutes () {
      return this.visibleStages.reduce((prevVal, stage) => prevVal + stage.timeInMins, 0)
    },
    totalTimeInSecs () {
      return this.totalTimeInMinutes * 60
    },
    meetingHasStarted () {
      return this.l10Data.meetingStatus === MEETING_STATUSES.STARTED
    },
    meetingHasEnded () {
      return this.l10Data.meetingStatus === MEETING_STATUSES.ENDED
    },
    showMeetingHasEndedCover () {
      return this.meetingHasEnded && !this.showMeetingDetailsEvenWhenEnded && !this.$route.query.showDetails
    },
    canHaveDrawer () {
      if (this.showMeetingHasEndedCover) return false
      return this.windowWidth > 700
    },
    showDrawer () {
      return this.canHaveDrawer && this.wantDrawer
    },
    contentWidth () {
      let width = this.windowWidth
      if (this.showDrawer) width -= this.drawerWidth
      return `${width}px`
    },
    displayTime () {
      const secs = this.l10Data.elapsedTimeInSecs % 60
      const mins = Math.floor(this.l10Data.elapsedTimeInSecs / 60) % 60
      const hours = Math.floor(this.l10Data.elapsedTimeInSecs / 60 / 60)
      return ('0' + hours).slice(-2) + ':' + ('0' + mins).slice(-2) + ':' + ('0' + secs).slice(-2)
    },
    totalTimeProgress () {
      return Math.ceil((this.l10Data.elapsedTimeInSecs / this.totalTimeInSecs) * 100)
    },
    selectedStageNoAsStep () {
      return this.selectedStageNo + 1
    },
    selectedStage () {
      return this.visibleStages[this.selectedStageNo]
    },
    currentStageProgress () {
      const stageStartInSecs = this.selectedStage.startTimeMins * 60
      const stageEndInSecs = this.selectedStage.endTimeMins * 60
      if (this.l10Data.elapsedTimeInSecs < stageStartInSecs) return 0
      if (this.l10Data.elapsedTimeInSecs > stageEndInSecs) return 100
      return Math.ceil(((this.l10Data.elapsedTimeInSecs - stageStartInSecs) / (stageEndInSecs - stageStartInSecs)) * 100)
    },
    carriedOverTodos () {
      const l10Todos = this.l10Data.todos?.todos || []
      return l10Todos.filter(todo => todo.status === 'Not done')
    },
    issuesTodos () {
      const l10Issues = this.l10Data.IDS?.issues || []

      return l10Issues
        .filter(issue => issue.todos.length > 0)
        .reduce((todos, issue) => [...todos, ...issue.todos], [])
    },
    extraTodos () {
      return this.l10Data.conclude?.extraTodos || []
    },
    summaryTodos () {
      const todos = [...this.carriedOverTodos, ...this.issuesTodos, ...this.extraTodos]

      return todos.slice()
        .sort((a, b) => {
          const nameA = this.$options.filters.nameByEmail(a.email, this.l10Data.attendees)
          const nameB = this.$options.filters.nameByEmail(b.email, this.l10Data.attendees)
          return ('' + nameA).localeCompare(nameB)
        })
    },
    averageRating () {
      // if user has rated consider that or else default 8
      const userRating = (email) => {
        const ratingByUser = this.l10Data.conclude.meetingRatings.find(mr => mr.email === email)
        return ratingByUser ? ratingByUser.rating : 8
      }

      // consider only present users
      const attendees = this.l10Data.attendees.filter(attendee => attendee.isPresent)
      const totalRatings = attendees.reduce((rating, user) => userRating(user.email) + rating, 0)

      return Math.floor((totalRatings / attendees.length) * 100) / 100
    },
    stageGuideText () {
      const dataField = this.selectedStage.dataField
      if (
        typeof this.l10Data[dataField] !== 'undefined' &&
        typeof this.l10Data[dataField].guideText !== 'undefined'
      ) {
        return this.l10Data[dataField].guideText
      }
      return this.selectedStage.defaultGuideText
    },
    canSync () {
      return !!(this.allowSync && this.twSyncOptions.accessToken && this.twSyncOptions.projectId && this.twSyncOptions.tasklistId)
    },
    warnToChooseOptionsBeforeMeetingEnd () {
      return this.twSyncOptions.accessToken && (!this.twSyncOptions.projectId || !this.twSyncOptions.tasklistId)
    },
    unSyncedTasks () {
      if (!this.allowSync || !this.twSyncOptions.accessToken || !this.twSyncOptions.projectId || !this.twSyncOptions.tasklistId) return 0

      const l10Todos = this.l10Data.todos?.todos || []
      const l10Issues = this.l10Data.IDS?.issues || []
      const extraTodos = this.l10Data.conclude?.extraTodos || []
      const allTodos = [...l10Todos, ...l10Issues, ...extraTodos]

      return allTodos.filter(todo => todo.synced === false).length
    }
  },
  watch: {
    initialL10Meta: {
      immediate: true,
      handler (meta) {
        this.l10Meta = { ...this.l10Meta, ...meta }
      }
    },
    initialL10Data: {
      immediate: true,
      handler: 'initializeL10Data'
    },
    twSyncOptions (options, prevOptions) {
      if (this.meetingHasEnded) return

      // unsynced
      if (!options.projectId && prevOptions.projectId && !options.tasklistId && prevOptions.tasklistId) {
        return this.resetAllTodoSyncOptions()
      }

      // synced
      this.l10Data.todos.todos.forEach(todo => this.setSyncOptions(todo))
      this.l10Data.IDS.issues.forEach(issue => {
        const todos = issue.todos || []
        todos.forEach(todo => this.setSyncOptions(todo))
      })
      this.l10Data.conclude.extraTodos.forEach(todo => this.setSyncOptions(todo))

      // initialise sync only if first time selection
      if (!prevOptions.projectId &&
        options.projectId &&
        !prevOptions.tasklistId && options.tasklistId &&
        this.l10Data.scribe === this.loggedInUserInfo.email) {
        this.syncTodosWithTeamworkTasks()
      }
    }
  },
  created () {
    if (!this.loggedInUserInfo.displayName.length) {
      console.error('logged in user not set')
    }

    this.initializeTimer()

    // Jump to the right stage for this time into the meeting
    this.jumpToRightStageForElapsedTime()

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    clearTimeout(this.meetingPulseTimer)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions([
      'setMessage',
      'getUserById',
      'getProjectById',
      'getTasklistById',
      'getTask',
      'quickAddTask',
      'updateTask',
      'updateTaskStatus',
      'deleteTodo',
      'getNotebookById',
      'sendMeetingSummaryMail'
    ]),
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    initializeL10Data (l10Data) {
      this.l10Data = { ...this.l10Data, ...l10Data }

      // Pick a random scribe/facilitator if one not picked
      if (!this.l10Data.scribe) this.chooseRandomScribe()
      if (this.l10Data.plan.hasFacilitator && !this.l10Data.facilitator) this.chooseRandomFacilitator()

      // save elapsed time after refresh
      this.getUnsavedElapsedTime()

      this.showMeetingDetailsEvenWhenEnded = false
    },
    // consider a scenario where someone is refreshing the page after starting meeting
    // we should save that elapsed time on very first load
    //
    getUnsavedElapsedTime () {
      if (!this.l10Data.meetingIsRunning || this.l10Data.meetingStatus !== MEETING_STATUSES.STARTED || this.lastUpdatedMinsBefore > this.totalTimeInMinutes) return
      const unixTimestamp = this.$helpers.getUnixTimestamp()
      const unsavedTime = unixTimestamp - this.l10Data.meetingStartedAt

      if (isNaN(unsavedTime) || unsavedTime <= 0) return
      // Update the database object
      this.l10Data.elapsedTimeInSecs += unsavedTime
    },
    initializeTimer () {
      this.meetingPulseTimer = setInterval(() => {
        if (!this.meetingHasStarted || !this.l10Data.meetingIsRunning) { return }
        this.l10Data.elapsedTimeInSecs += 1 // add one second on
      }, 1000)
    },
    jumpToRightStageForElapsedTime () {
      let totalTime = 0
      const elapsedTimeInMin = this.l10Data.elapsedTimeInSecs / 60

      for (let i = 0; i < this.visibleStages.length; i++) {
        totalTime += this.visibleStages[i].timeInMins
        if (elapsedTimeInMin <= totalTime) {
          this.selectedStageNo = i
          break
        }
      }

      if (elapsedTimeInMin > totalTime) {
        this.selectedStageNo = this.visibleStages.length - 1
      }
    },
    colorForPercent (progress) {
      if (progress > 99) return 'red'
      if (progress > 98) return '#f6512e'
      if (progress > 97) return '#f86024'
      if (progress > 96) return '#fa711a'
      if (progress > 95) return '#fc830e'
      if (progress > 90) return 'orange'
      return 'teal'
    },
    async updateStepGuide (text) {
      const dataField = this.selectedStage.dataField
      if (typeof this.l10Data[dataField] === 'undefined') this.l10Data[dataField] = {}
      if (!text || text === this.selectedStage.defaultGuideText) {
        this.$delete(this.l10Data[dataField], 'guideText')
      } else {
        this.$set(this.l10Data[dataField], 'guideText', text)
      }

      try {
        this.isUpdatingStepGuide = true
        const updatedObject = {}
        updatedObject[`data.${dataField}`] = this.l10Data[dataField]
        await this.dataManager.updateObject('level10s', this.level10Id, updatedObject)
        this.$refs.stepGuide.closeEditDialog()
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      } finally {
        this.isUpdatingStepGuide = false
      }
    },
    goToPrevStep () {
      if (this.selectedStageNo <= 0) return
      this.selectedStageNo--
    },
    goToNextStep () {
      if (this.selectedStageNo >= this.visibleStages.length - 1) return
      this.selectedStageNo++
    },
    changeStep (index) {
      this.selectedStageNo = index
    },
    openDialogAttendeeList () {
      if (this.meetingHasEnded) {
        return this.setMessage({ type: 'error', message: 'You cannot add or edit attendees for an ended meeting' })
      }
      this.$refs.toolbar.managePermissions()
    },
    getUniqueUser () {
      const ignoreEmails = [this.loggedInUserInfo.email, this.l10Data.scribe, this.l10Data.facilitator]

      const attendees = this.l10Data.attendees.slice()
      const otherPresentAttendees = attendees.filter(attendee => {
        return !ignoreEmails.includes(attendee.email) && attendee.isPresent
      }).map(attendee => attendee.email)

      if (otherPresentAttendees.length === 0) return this.loggedInUserInfo.email

      const randNumber = this.$helpers.generateRandomNumber(otherPresentAttendees.length)
      return otherPresentAttendees[randNumber]
    },
    chooseRandomFacilitator () {
      const randomAttendeeEmail = this.getUniqueUser()
      this.setAttendeeStatus({ attendees: this.l10Data.attendees, facilitator: randomAttendeeEmail })
    },
    chooseRandomScribe () {
      const randomAttendeeEmail = this.getUniqueUser()
      this.setAttendeeStatus({ attendees: this.l10Data.attendees, scribe: randomAttendeeEmail })
    },
    updateAttendeeStatus ({ email, status }) {
      const attendees = this.l10Data.attendees.slice()
      const index = attendees.findIndex(u => u.email === email)
      // check user exists
      if (index === -1) return
      attendees[index].isPresent = status !== 'away'

      const data = {
        attendees,
        scribe: status === 'scribe' ? attendees[index].email : '',
        facilitator: status === 'facilitator' ? attendees[index].email : ''
      }

      this.setAttendeeStatus(data)
    },
    setAttendeeStatus (data) {
      if (data.scribe) this.$set(this.l10Data, 'scribe', data.scribe)
      if (data.facilitator) this.$set(this.l10Data, 'facilitator', data.facilitator)

      // Update the database object
      try {
        const facilitator = data.facilitator || this.l10Data.facilitator
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.attendees': data.attendees,
          'data.scribe': data.scribe || this.l10Data.scribe,
          'data.facilitator': facilitator,
          'data.plan.hasFacilitator': !!facilitator
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    async startMeeting () {
      try {
        if (this.meetingHasEnded) return this.setMessage({ type: 'error', message: 'You cannot start an ended meeting' })
        // sync todos with teamwork tasks
        await this.syncTodosFromTeamworkTasks()

        this.$set(this.l10Data, 'meetingStatus', MEETING_STATUSES.STARTED)
        this.$set(this.l10Data, 'meetingIsRunning', true)
        // Update the database object
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.meetingStatus': this.l10Data.meetingStatus,
          'data.meetingIsRunning': this.l10Data.meetingIsRunning,
          'data.elapsedTimeInSecs': this.l10Data.elapsedTimeInSecs,
          'data.meetingStartedAt': this.$helpers.getUnixTimestamp()
        })

        if (this.l10Data.elapsedTimeInSecs === 0) this.$analytics('meeting_started', { id: this.level10Id })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    toggleMeetingStatus () {
      try {
        if (this.meetingHasEnded) return this.setMessage({ type: 'error', message: 'You cannot start an ended meeting' })

        const newMeetingIsRunning = !this.l10Data.meetingIsRunning
        this.$set(this.l10Data, 'meetingIsRunning', newMeetingIsRunning)
        // Update the database object
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.meetingIsRunning': newMeetingIsRunning,
          'data.elapsedTimeInSecs': this.l10Data.elapsedTimeInSecs,
          'data.meetingStartedAt': this.$helpers.getUnixTimestamp()
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    async beforeMeetingEnd () {
      if (this.syncingItems || this.syncingSilent) return this.setMessage({ type: 'error', message: 'Please wait...synchromization is in progress.' })
      // alert if user is trying to close meeting with date
      const dateInLocalTimezone = this.dataManager.getDateInLocalTZ(this.l10Meta.meetingDateYYYYMMDD)
      const diffDays = Math.ceil((dateInLocalTimezone - new Date()) / (1000 * 60 * 60 * 24))
      // check dialog is not already open
      if (diffDays > 0 && !this.showDialogEndingFutureMeeting) {
        this.showDialogEndingFutureMeeting = true
      }
      this.showDialogEndingFutureMeeting = false

      if (!this.allowSync) this.endMeeting()

      const notebookExists = await this.checkNotebookExists()
      if (!notebookExists) return this.$emit('show-sync-options')

      this.twSyncOptions.accessToken && this.twSyncOptions.projectId && this.twSyncOptions.tasklistId ? this.endMeeting() : this.showDialogSyncWithTeamwork = true
    },
    syncWithTeamwork () {
      this.showDialogSyncWithTeamwork = false
      this.twSyncOptions.accessToken ? this.$emit('show-sync-options') : this.$emit('initialize-tw-oauth-process')
    },
    setSyncOptions (todo) {
      if (todo.taskId) return
      todo.synced = false

      this.$set(todo, 'projectId', this.twSyncOptions.projectId)
      this.$set(todo, 'tasklistId', this.twSyncOptions.tasklistId)

      return todo
    },
    async resetAllTodoSyncOptions () {
      this.l10Data.todos.todos.forEach(todo => this.resetSyncOptions(todo))
      this.l10Data.IDS.issues.forEach(issue => {
        const todos = issue.todos || []
        todos.forEach(todo => this.resetSyncOptions(todo))
      })
      this.l10Data.conclude.extraTodos.forEach(todo => this.resetSyncOptions(todo))

      await this.$nextTick()
      this.updateSummaryTodos({
        todos: this.l10Data.todos.todos,
        issues: this.l10Data.IDS.issues,
        extraTodos: this.l10Data.conclude.extraTodos
      })
    },
    resetSyncOptions (todo, projectId = null, tasklistId = null) {
      todo.synced = false
      this.$set(todo, 'projectId', projectId)
      this.$set(todo, 'tasklistId', tasklistId)
      this.$set(todo, 'taskId', null)

      return todo
    },
    async endMeeting () {
      try {
        this.showDialogSyncWithTeamwork = false
        await this.syncTodosWithTeamworkTasks()
        this.$set(this.l10Data, 'meetingIsRunning', false)
        this.$set(this.l10Data, 'meetingStatus', MEETING_STATUSES.ENDED)
        // Update the database object
        await this.dataManager.updateObject('level10s', this.level10Id, {
          'data.meetingIsRunning': this.l10Data.meetingIsRunning,
          'data.elapsedTimeInSecs': this.l10Data.elapsedTimeInSecs,
          'data.meetingStatus': this.l10Data.meetingStatus
        })
        this.$emit('export-to-notebook')
        this.$analytics('meeting_ended', { id: this.level10Id })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    onSyncStart () {
      this.totalItemsToSync = 0
      this.totalItemsSynced = 0
      this.syncingItems = true

      return this.checkProjectAndTasklistExists()
    },
    async onSyncEnd () {
      // gradually increase progress
      while (this.totalItemsSynced <= this.totalItemsToSync) {
        await new Promise(resolve => setTimeout(() => resolve(this.totalItemsSynced++), 500))
      }
      this.syncingItems = false
      return Promise.resolve()
    },
    async checkProjectAndTasklistExists () {
      try {
        this.totalItemsToSync += 2
        await this.getProjectById(this.twSyncOptions.projectId)
        await this.getTasklistById(this.twSyncOptions.tasklistId)
        return Promise.resolve()
      } catch (error) {
        this.syncingItems = false
        this.totalItemsSynced = this.totalItemsToSync

        const notFound = error.response.status === 404
        if (!notFound) throw error

        this.$emit('show-sync-options')
        this.$emit('refresh-tasklist')
        this.resetAllTodoSyncOptions()

        error.message = 'Please make sure the project and tasklist exists'
        throw error
      }
    },
    async checkNotebookExists () {
      if (!this.twSyncOptions.accessToken || !this.twSyncOptions.notebookId || isNaN(this.twSyncOptions.notebookId / 1)) {
        return Promise.resolve(true)
      }

      try {
        await this.getNotebookById(this.twSyncOptions.notebookId / 1)
        return Promise.resolve(true)
      } catch (error) {
        const notFound = error.response.status === 404
        this.setMessage({ type: 'error', message: notFound ? 'Please make sure notebook exists' : error.message })

        if (notFound) {
          await this.dataManager.updateObject('level10s', this.level10Id, {
            'meta.twSyncOptions.notebookId': null
          })
        }
        return Promise.resolve(false)
      }
    },
    // get respected task from teamwork
    // apply update(s) (if any) to meeting todos
    //
    async syncTodosFromTeamworkTasks () {
      if (!this.allowSync || !this.twSyncOptions.accessToken || !this.twSyncOptions.projectId || !this.twSyncOptions.tasklistId) return Promise.resolve()

      await this.onSyncStart()

      const todos = this.l10Data.todos.todos.map(todo => this.convertTaskToTodoObj(todo, true))
      const IDSIssues = this.l10Data.IDS.issues.map(async (issue) => {
        issue.todos = await Promise.all(issue.todos.map(todo => this.convertTaskToTodoObj(todo)))
        return issue
      })
      const extraTodos = this.l10Data.conclude.extraTodos.map(todo => this.convertTaskToTodoObj(todo))

      const syncedTodos = await Promise.all(todos)
      const syncedIDSIssues = await Promise.all(IDSIssues)
      const syncedExtraTodos = await Promise.all(extraTodos)

      await this.onSyncEnd()

      this.dataManager.updateObject('level10s', this.level10Id, {
        'data.attendees': this.l10Data.attendees
      })

      this.$analytics('meeting_using_sync', { id: this.level10Id })

      // remove null = deleted
      return this.updateSummaryTodos({
        todos: syncedTodos,
        issues: syncedIDSIssues,
        extraTodos: syncedExtraTodos
      })
    },
    async convertTaskToTodoObj (todo, withStatusAndProgress = false) {
      if (!todo.taskId) return Promise.resolve(todo)

      try {
        this.totalItemsToSync += 1

        const { data } = await this.getTask(todo.taskId)
        const task = data['todo-item']

        // remove deleted todo
        if (task.status === 'deleted') return Promise.resolve(null)

        // make todo object to check against
        const newObj = {
          id: todo.id,
          email: todo.email,
          taskId: todo.taskId,
          synced: todo.synced,
          projectId: task['project-id'],
          tasklistId: task['todo-list-id'],
          todo: task.content,
          startDate: task['start-date'] ? task['start-date'].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3') : '',
          endDate: task['due-date'] ? task['due-date'].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3') : ''
        }

        if (withStatusAndProgress) {
          newObj.status = task.completed ? 'Done' : 'Not done'
          newObj.progress = task.progress || 0
        }

        // check if there is an assignee
        const assignedTo = task['responsible-party-id']
        newObj.email = await this.checkForAssigneeUpdate(todo, assignedTo)
        newObj.otherAssignees = await this.checkForOtherAssigneesUpdates(todo, assignedTo)

        return Promise.resolve(newObj)
      } catch (error) {
        this.resetSyncOptions(todo, this.twSyncOptions.projectId, this.twSyncOptions.tasklistId)
      } finally {
        this.totalItemsSynced += 1
      }
    },
    addOrEditAttendee (attendee) {
      const existingUser = this.l10Data.attendees.find(user => user.email === attendee.email)
      attendee.isPresent = existingUser ? existingUser.isPresent : false
      attendee.photoURL = existingUser ? existingUser.photoURL : ''

      existingUser ? Object.assign(existingUser, attendee) : this.l10Data.attendees.push(attendee)

      const usersMeta = Object.assign({}, this.l10Meta.permissions)
      usersMeta[attendee.email] = this.l10Meta.permissions[attendee.email] || DEFAULT_USER_PERMISSIONS

      this.$emit('update-permissions', {
        domains: this.l10Meta.domains,
        usersMeta,
        users: this.l10Data.attendees
      })
    },
    async checkForAssigneeUpdate (todo, assignedTo) {
      // assigned to anyone
      if (!assignedTo) {
        const existingAssignee = this.l10Data.attendees.find(user => user.email === todo.email)
        // if, a todo is already assigned to someone who is not registered with teamwork then
        // keep it as it is
        if (existingAssignee && !existingAssignee.handle) return Promise.resolve(todo.email)
        // else, assigned to someone here but now it's been set to anyone on teamwork
        // and existing assignee is already teamwork member remove from his/her list
        // create an nonymous user if not exists
        this.addOrEditAttendee({
          displayName: this.anonymousUser.displayName,
          email: this.anonymousUser.email
        })
        return Promise.resolve(this.anonymousUser.email)
      }

      // has assignee
      const { data } = await this.getUserById(parseInt(assignedTo, 10))
      const person = data.person

      // if assignee is same
      if (person.emailAddress === todo.email) return Promise.resolve(todo.email)

      // add or update new assignee in attendees list
      this.addOrEditAttendee({
        displayName: `${person.firstName} ${person.lastName}`,
        email: person.emailAddress,
        twId: person.id
      })

      return Promise.resolve(person.emailAddress)
    },
    async checkForOtherAssigneesUpdates (todo, assignees = '') {
      const otherAssignees = assignees.split(',')
      otherAssignees.shift() // remove primary assignee

      // no other assignees
      if (otherAssignees.length === 0) return Promise.resolve([])

      const promises = otherAssignees.map(async (userId) => {
        try {
          const { data } = await this.getUserById(parseInt(userId, 10))
          const person = data.person

          // verify person exists in attendees list
          const existsInAttendees = this.l10Data.attendees.find(user => user.email === person.emailAddress)
          // add to the list
          if (!existsInAttendees) {
            this.addOrEditAttendee({
              displayName: `${person.firstName} ${person.lastName}`,
              email: person.emailAddress,
              twId: person.id
            })
          }

          // someone added a user in teamwork todo so we need to reflect that here too
          const isNewAssignee = !(todo.otherAssignees || []).includes(person.emailAddress)
          if (isNewAssignee) {
            todo.assignees = (todo.otherAssignees || []).push(person.emailAddress)
          }

          return Promise.resolve(person.emailAddress)
        } catch (error) {
          Promise.resolve(null)
        }
      })

      await Promise.all(promises)

      return todo.otherAssignees || []
    },
    // get all meeting todos
    // if a todo already has task created on Teamwwork than update or else create a new task
    //
    async syncTodosWithTeamworkTasks () {
      if (!this.allowSync || !this.twSyncOptions.accessToken || !this.twSyncOptions.projectId || !this.twSyncOptions.tasklistId) {
        return Promise.resolve()
      }

      await this.onSyncStart()

      const todos = this.l10Data.todos.todos.map(todo => this.createOrUpdateTask(todo))
      const IDSIssues = this.l10Data.IDS.issues.map(async (issue) => {
        issue.todos = await Promise.all(issue.todos.map(todo => this.createOrUpdateTask(todo)))
        return issue
      })
      const extraTodos = this.l10Data.conclude.extraTodos.map(todo => this.createOrUpdateTask(todo))

      const syncedTodos = await Promise.all(todos)
      const syncedIDSIssues = await Promise.all(IDSIssues)
      const syncedExtraTodos = await Promise.all(extraTodos)

      await this.onSyncEnd()

      return this.updateSummaryTodos({
        todos: syncedTodos,
        issues: syncedIDSIssues,
        extraTodos: syncedExtraTodos
      })
    },
    async createOrUpdateTask (todo) {
      if (todo.synced === false && todo.projectId && todo.tasklistId) {
        this.totalItemsToSync += 1
        // Fix multiple task issue
        // Ref: https://developer.teamwork.com/projects/api-v1/ref/tasks/post-projects-projid-tasks-quickadd-json
        todo.todo = todo.todo.replace(/\n/g, ' ').replace(/~\|~/g, ' ')
        todo.taskId = todo.taskId ? await this.updateTeamworkTodo(todo) : await this.createTeamworkTodo(todo)
        if (todo.status) this.updateTaskStatus({ taskId: todo.taskId, status: todo.status === 'Done' ? 'complete' : 'uncomplete' })
        this.totalItemsSynced += 1
      }
      todo.synced = true
      return Promise.resolve(todo)
    },
    async createTeamworkTodo (task) {
      const responsiblePatryIds = this.l10Data.attendees
        .filter(attendee => [task.email, ...task.otherAssignees || []].includes(attendee.email) && attendee.twId)
        .map(attendee => attendee.twId)

      const payload = {
        'use-defaults': true,
        'todo-item': {
          'responsible-party-id': responsiblePatryIds.join(',')
        },
        tasklistId: task.tasklistId,
        content: task.todo
      }

      if (task.startDate) payload['todo-item']['start-date'] = task.startDate.replace(/-/g, '')
      if (task.endDate) payload['todo-item']['due-date'] = task.endDate.replace(/-/g, '')

      const { data } = await this.quickAddTask(payload)
      task.taskId = data.taskIds
      // immediately update as quick add supports content only
      if (task.progress) return this.updateTeamworkTodo(task)
      return Promise.resolve(task.taskId)
    },
    async updateTeamworkTodo (task) {
      try {
        const responsiblePatryIds = this.l10Data.attendees
          .filter(attendee => [task.email, ...task.otherAssignees || []].includes(attendee.email) && attendee.twId)
          .map(attendee => attendee.twId)

        const payload = {
          id: task.taskId,
          tasklistId: task.tasklistId,
          content: task.todo,
          progress: Math.ceil(task.progress || 0),
          'responsible-party-id': responsiblePatryIds.join(',')
        }

        if (task.startDate) payload['start-date'] = task.startDate.replace(/-/g, '')
        if (task.endDate) payload['due-date'] = task.endDate.replace(/-/g, '')

        await this.updateTask(payload)
      } catch (error) {
        // completed task can't be edited, so mark it as uncomplete first
        if (error.response.status !== 403) throw error
        await this.updateTaskStatus({ taskId: task.taskId, status: 'uncomplete' })
        return this.updateTeamworkTodo(task)
      }

      return Promise.resolve(task.taskId)
    },
    // get all the meeting todos
    // if a todo was updated here (synced = false) than apply those updates to respected Teamwork task or
    // create a new task if there is no Teamwork task attached
    // get and appy any change(s) from teamwork Task if user haven't made any change(s)
    //
    async twoWaySyncTodos () {
      try {
        this.syncingSilent = true
        await this.checkProjectAndTasklistExists()

        const todos = this.l10Data.todos.todos.map(todo => {
          return todo.taskId && todo.synced ? this.convertTaskToTodoObj(todo, true) : this.createOrUpdateTask(todo)
        })

        const IDSIssues = this.l10Data.IDS.issues.map(async (issue) => {
          issue.todos = await Promise.all(issue.todos.map(todo => {
            return todo.taskId && todo.synced ? this.convertTaskToTodoObj(todo) : this.createOrUpdateTask(todo)
          }))
          return issue
        })

        const extraTodos = this.l10Data.conclude.extraTodos.map(todo => {
          return todo.taskId && todo.synced ? this.convertTaskToTodoObj(todo) : this.createOrUpdateTask(todo)
        })

        const syncedTodos = await Promise.all(todos)
        const syncedIDSIssues = await Promise.all(IDSIssues)
        const syncedExtraTodos = await Promise.all(extraTodos)

        this.updateSummaryTodos({
          todos: syncedTodos,
          issues: syncedIDSIssues,
          extraTodos: syncedExtraTodos
        })

        this.$analytics('meeting_using_two_way_sync', { id: this.level10Id })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      } finally {
        this.syncingSilent = false
      }
    },
    async updateMeetingSectionTimings ({ field, value }) {
      try {
        this.$set(this.l10Data.plan, field, value)
        await this.dataManager.updateObject('level10s', this.level10Id, {
          'data.plan': this.l10Data.plan
        })

        this.$analytics('meeting_customized_timing', { id: this.level10Id })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    toggleMeetingSection ({ field, isVisible }) {
      const hiddenSections = this.hiddenSections || []
      if (!isVisible) {
        hiddenSections.push(field)
      } else {
        const index = hiddenSections.indexOf(field)
        hiddenSections.splice(index, 1)
      }

      this.$set(this.l10Data.plan, 'hiddenSections', hiddenSections)
      this.dataManager.updateObject('level10s', this.level10Id, {
        'data.plan.hiddenSections': hiddenSections
      })

      this.$analytics('meeting_toggled_section', { id: this.level10Id })
    },
    async updateMeetingPlanData (planData) {
      try {
        this.isUpdatingMeetingPlan = true
        this.$set(this.l10Data, 'plan', planData)
        await this.dataManager.updateObject('level10s', this.level10Id, {
          'data.plan': this.l10Data.plan
        })
        this.$refs.stepMeetingPlanner.closeDialogEditNotes()
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      } finally {
        this.isUpdatingMeetingPlan = false
      }
    },
    updateSegueData (segueData) {
      try {
        this.$set(this.l10Data, 'segue', segueData)
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.segue': this.l10Data.segue
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    updateScrorecardData (scorecardData) {
      try {
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.scorecard': scorecardData
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    addIDSIssue (issue) {
      const issues = this.l10Data.IDS.issues
      // check that the issue isn't logged already
      const pos = issues.findIndex(issueObj => issueObj.issue.toLowerCase() === issue.toLowerCase())
      if (pos > -1) return this.setMessage({ type: 'error', message: 'Issue already logged' })

      const newIssueItem = {
        id: this.$helpers.generateUniqueId(),
        email: this.loggedInUserInfo.email,
        issue,
        status: 'Not selected',
        votes: [],
        todos: []
      }

      try {
        this.l10Data.IDS.issues.push(newIssueItem)
        // Update the database object
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.IDS': this.l10Data.IDS
        })

        this.setMessage({ type: 'success', message: 'Issue logged' })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    updateRocksData (rocksData) {
      try {
        this.$set(this.l10Data, 'rocks', rocksData)
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.rocks': this.l10Data.rocks
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    updatedHeadlinesData (headlinesData) {
      try {
        this.$set(this.l10Data, 'headlines', headlinesData)
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.headlines': this.l10Data.headlines
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    updatedTodosData (todosData = { todos: [] }) {
      try {
        todosData.todos = (todosData.todos || []).filter(todo => todo !== null)
        this.$set(this.l10Data, 'todos', todosData)
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.todos': this.l10Data.todos
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    updateIDSData (IDSData) {
      try {
        this.$set(this.l10Data, 'IDS', IDSData)
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.IDS': this.l10Data.IDS
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    updateConcludeData (concludeData) {
      try {
        this.$set(this.l10Data, 'conclude', concludeData)
        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.conclude': this.l10Data.conclude
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    updateSummaryTodos (data) {
      try {
        data.issues = data.issues.map(issue => {
          issue.todos = (issue.todos || []).filter(todo => todo !== null)
          return issue
        })

        this.dataManager.updateObject('level10s', this.level10Id, {
          'data.todos.todos': data.todos.filter(todo => todo !== null),
          'data.IDS.issues': data.issues,
          'data.conclude.extraTodos': data.extraTodos.filter(todo => todo !== null)
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },
    async updateNotesToShare (message) {
      try {
        this.updatingNotesToShare = true
        this.$set(this.l10Data.conclude, 'messageToShare', message)
        await this.dataManager.updateObject('level10s', this.level10Id, {
          'data.conclude.messageToShare': message
        })
        this.$refs.meetinStepConcludeNotes.closeEditDialog()
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      } finally {
        this.updatingNotesToShare = false
      }
    },
    async setupNextMeeting (sendSummary = true) {
      const meetingPlan = this.l10Data.plan || {}
      Object.assign(this.l10Data.plan, { ...meetingPlan, carriedOver: true })

      // Create a seguence if we don't have one already
      if (typeof this.l10Meta.seguence !== 'object') {
        this.$set(this.l10Meta, 'seguence', { seguenceId: this.dataManager.getUniqueId() })
      }

      try {
        this.settingUpNextMeeting = true
        // Create a new empty L10, copy in data and set seguence, add reference to current as "next"
        const opts = {}
        opts.meta = JSON.parse(JSON.stringify(this.l10Meta)) // deep clone
        opts.meta.seguence.originalId = this.level10Id // always points this meeting
        if (typeof this.l10Meta.seguence.previousId === 'string') {
          opts.meta.seguence.previousId = this.l10Meta.seguence.previousId // always points this meeting
        }
        opts.meta.seguence.nextId = this.level10Id // always points this meeting
        opts.data = JSON.parse(JSON.stringify(this.l10Data)) // deep clone
        opts.data.meetingIsRunning = false

        const { objectId } = await this.dataManager.createObject('level10s', opts)

        // Reconfigure the current meeting for next week
        // set meeting date to next week
        // set previous to the one we just inserted
        const currentMeetingDate = this.dataManager.getDateInLocalTZ(this.l10Meta.meetingDateYYYYMMDD)
        const nextMeetingDate = this.dataManager.getDateInYYYYMMDD(currentMeetingDate.setDate(currentMeetingDate.getDate() + 7))

        // Reset data for next week
        this.$set(this.l10Meta.seguence, 'previousId', objectId)
        this.$set(this.l10Meta, 'meetingDateYYYYMMDD', nextMeetingDate)
        this.$set(this.l10Data, 'meetingStatus', MEETING_STATUSES.PLANNED)
        this.$set(this.l10Data, 'elapsedTimeInSecs', 0)
        this.$set(this.l10Data.segue, 'notes', [])
        this.$set(this.l10Data.headlines, 'headlines', [])

        // Add new week to scorecard
        const currentMetrics = opts.data.scorecard?.metrics || []
        const newMetrics = []
        currentMetrics.forEach(metric => {
          const newMetric = Object.assign({}, metric)
          const scores = newMetric.scores || newMetric.weekScores || []
          scores.shift()
          scores.push({ score: null, reason: '' })
          newMetric.scores = scores
          newMetrics.push(newMetric)
        })
        // Add monthly scorecard as it is
        const monthlyMetrics = opts.data.scorecard?.monthlyMetrics || []
        // Add score date to new column
        const scoreDates = (opts.data.scorecard?.scoreDates || []).slice()
        const currWeek = currentMeetingDate.setDate(currentMeetingDate.getDate() - 6)
        let nextColFrom = this.dataManager.getDateInYYYYMMDD(currWeek)
        let nextColTo = nextMeetingDate

        // consider last column dates if it's filled up
        if (scoreDates.length > 0 && scoreDates[scoreDates.length - 1].to) {
          const lastDate = this.dataManager.getDateInLocalTZ(new Date(scoreDates[scoreDates.length - 1].to))
          nextColFrom = this.dataManager.getDateInYYYYMMDD(lastDate.setDate(lastDate.getDate() + 1))
          nextColTo = this.dataManager.getDateInYYYYMMDD(lastDate.setDate(lastDate.getDate() + 6))
        }

        scoreDates.shift()
        scoreDates.push({
          from: nextColFrom,
          to: nextColTo
        })

        this.$set(this.l10Data, 'scorecard', {
          metrics: newMetrics,
          monthlyMetrics: JSON.parse(JSON.stringify(monthlyMetrics)),
          scoreDates,
          hiddenColumns: opts.data.scorecard?.hiddenColumns || {
            weekly: [],
            monthly: []
          }
        })

        // Remove all completed tasks
        const summaryTodosCopy = this.summaryTodos.map(summaryTodo => {
          delete summaryTodo.isNew
          return {
            ...summaryTodo,
            status: 'Not done'
          }
        })
        this.$set(this.l10Data.todos, 'todos', summaryTodosCopy)
        this.$set(this.l10Data.conclude, 'extraTodos', [])
        this.$set(this.l10Data.conclude, 'messageToShare', '')

        // Remove all discussed issues and hanging tasks
        const issues = this.l10Data.IDS.issues
          .filter(issue => issue.status !== 'Discussed')
          .map(issue => ({
            ...issue,
            todos: [],
            votes: [],
            status: 'Not selected'
          }))
        this.$set(this.l10Data.IDS, 'issues', issues)

        // Reset all meeting ratings
        for (let mrNo = this.l10Data.conclude.meetingRatings.length - 1; mrNo >= 0; mrNo--) {
          this.$set(this.l10Data.conclude.meetingRatings[mrNo], 'rating', 8)
          this.$set(this.l10Data.conclude.meetingRatings[mrNo], 'comment', '')
        }

        // Update the database object
        this.selectedStageNo = 0
        this.dataManager.updateObject('level10s', this.level10Id, {
          meta: this.l10Meta,
          data: this.l10Data
        })

        this.$analytics('meeting_created_next', { id: this.level10Id })
        // Send summary email
        if (sendSummary) {
          this.sendMeetingSummaryMail(objectId)
          this.$analytics('meeting_sent_summary', { id: this.level10Id })
        }
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      } finally {
        this.settingUpNextMeeting = false
      }
    },
    addMember () {
      this.$refs.toolbar.openDialogAddMember()
    },
    toggleFacilitator (isEnabled) {
      isEnabled ? this.chooseRandomFacilitator() : this.dataManager.updateObject('level10s', this.level10Id, {
        'data.plan.hasFacilitator': false,
        'data.facilitator': null
      })
    },
    goToTWTask (taskId) {
      window.open(`${this.l10Meta.twSyncOptions.domain}#tasks/${taskId}`)
      this.$analytics('meeting_visited_tw_task', { id: taskId })
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-meeting__drawer {
  border-right:1px solid #EEE;
}

.eos-meeting__content {
  height: calc(100vh - 144px);

  &:not(.scorecard) {
    overflow-y: auto;
  }
}

.eos-meeting__quote--completed {
  line-height: 160%;
  background:#EEE;
  color:#333;
}

/* Used across multiple components */
::v-deep .eos-tbl__content-grow {
  @media screen and (min-width: 992px) {
    max-width: calc(100vw - 800px);
    overflow-wrap: break-word;
  }
}

::v-deep .ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

::v-deep .draggable-list-item {
  cursor: move;
}
</style>
