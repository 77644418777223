<template>
  <v-layout column fill-height :align-center="!hasContent" :justify-center="!hasContent">
    <AppLoader v-if="isLoading" />

    <AppErrorCard
      v-else-if="hasError"
      entity="weekly meeting"
      :is-anonymous-user="loggedInUserInfo.isAnonymous"
      :is-invited="!!$route.query.invited"
      :invitee-email="$route.query.email"
      :show-auth-loader="showAuthLoader"
      @reload="reloadLevel10Details"
      @login-with-google="$emit('login-with-google')"
      @login-with-microsoft="$emit('login-with-microsoft')"
      @login-with-email-pass="$emit('login-with-email-pass', $event)"
      @signup-with-email-pass="$emit('signup-with-email-pass', $event)"
      @reset-password="$emit('reset-password', $event)"
      @update-password="$emit('update-password', $event)"
    />

    <template v-else>
      <v-snackbar
        v-if="appMessage"
        :color="appMessageType"
        :value="true"
        top
        right
        timeout="-1"
      >
        <p>
          {{ appMessage }}
        </p>
        <div class="d-flex justify-space-between align-center">
          <p class="mb-0">
            Please contact us via <a href="mailto:support@instantagencytools.com" class="white--text font-weight-medium">support@instantagencytools.com</a> if you have any questions.
          </p>
          <v-btn text @click="appMessage = ''">
            Dismiss
          </v-btn>
        </div>
      </v-snackbar>

      <v-snackbar
        v-if="level10Object.meta.notebookExportError"
        :value="true"
        bottom
        class="mb-5"
        timeout="-1"
        color="warning"
      >
        <span v-if="level10Object.meta.notebookExportError === 403" class="white--text">
          User
          <template v-if="level10Object.meta.twSyncOptions.syncedBy">
            ({{ level10Object.meta.twSyncOptions.syncedBy }})
          </template> doesn't have permissions to add notebooks on the selected project. Please ask the project owner to grant the user required access. Learn more:
          <a href="https://support.teamwork.com/projects/planning-managing-work/setting-user-permissions-on-a-project" target="_blank" class="ml-2 white--text">Setting User Permissions on a Project</a>
        </span>
        <span v-else class="white--text">
          There was an error while exporting meeting to Teamwork notebook.
        </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            :loading="isExportingToNotebook"
            v-bind="attrs"
            @click="tryExport"
          >
            Retry
          </v-btn>
        </template>
      </v-snackbar>

      <MeetingToolbar
        :name="level10Object.meta.name"
        :date="level10Object.meta.meetingDateYYYYMMDD"
        :visibility="level10Object.meta.visibility"
        :previous-id="previousMeetingId"
        :next-id="nextMeetingId"
        :allow-update="allowUpdate"
        :allow-delete="allowDelete"
        :allow-sync="enableSyncOption"
        :tw-sync-options="level10Object.meta.twSyncOptions"
        @edit-name="showLevel10NameEditForm"
        @change-date="updateLevel10Date"
        @toggle-privacy="toggleLevel10Privacy"
        @delete="showDialogConfirmDelete = true"
        @import-export="openImportExportModal"
        @initialize-tw-oauth-process="$refs.appTWSync.initiateTeamworkOAuth()"
        @show-sync-options="$refs.appTWSync.showDialogSyncOptions = true"
        @save-sync-options="$refs.appTWSync.saveTWAuthTokenAndURL($event)"
      />

      <v-flex pa-0 ma-0 fill-height class="eos-l10__content">
        <EOSLevel10
          ref="eosLevel10"
          :logged-in-user-info="loggedInUserInfo"
          :anonymous-user="anonymousUser"
          :level10-id="$route.params.level10Id"
          :initial-l10-meta="level10Object.meta"
          :initial-l10-data="level10Object.data"
          :tw-sync-options="level10Object.meta.twSyncOptions"
          :tw-projects="twProjects"
          :tw-tasklists="twTasklists"
          :allow-sync="enableSyncOption"
          :last-updated-mins-before="lastUpdatedMinsBefore"
          @toggle-privacy="toggleLevel10Privacy"
          @update-permissions="updatePermissions"
          @initialize-tw-oauth-process="$refs.appTWSync.initiateTeamworkOAuth()"
          @refresh-tasklist="$refs.appTWSync.fetchTasklists($event)"
          @show-sync-options="$refs.appTWSync.showDialogSyncOptions = true"
          @export-to-notebook="tryExport"
        />
      </v-flex>
    </template>

    <MeetingDialogEditName
      v-if="showEditDialog"
      :name="level10Object.meta.name"
      :show-loader="savingLevel10Name"
      label="Weekly Meeting Name"
      @close="closeLevel10NameEditForm"
      @save="saveLevel10Name"
    />

    <AppDialogJSONImportExport
      v-if="showImportExportModal"
      title="Weekly Meeting Data"
      :dataJSON="level10JSON"
      :allow-update="allowUpdate"
      :show-loader="isUpdatingLevel10JSON"
      @update-data="updateLevel10JSON"
      @close="closeImportExportModal"
    />

    <AppTeamworkSync
      v-if="hasContent"
      ref="appTWSync"
      collection-name="level10s"
      enable-notebook-export
      entity="to-dos"
      :doc-id="$route.params.level10Id"
      :tw-sync-options="level10Object.meta.twSyncOptions"
      @tw-projects="projects => twProjects = projects"
      @tw-tasklists="tasklists => twTasklists = tasklists"
    />

    <AppDialogConfirmDelete
      v-if="showDialogConfirmDelete"
      message="You want to delete this weekly meeting?"
      @confirm="deleteLevel10"
      @cancel="showDialogConfirmDelete = false"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AppErrorCard from '@/components/shared/errors/AppErrorCard'
import AppDialogJSONImportExport from '@/components/shared/dialogs/AppDialogJSONImportExport'
import AppTeamworkSync from '@/components/shared/sync/AppTeamworkSync'
import MeetingToolbar from '@/components/meeting/MeetingToolbar'
import MeetingDialogEditName from '@/components/meeting/dialogs/MeetingDialogEditName'
import EOSLevel10 from '@/components/eos/EOSLevel10'

import DataManager from '@/helpers/dataManager'
import FIREBASE_PERMISSION_ERROR_CODES from '@/enums/firebasePermissionErrorCodes'
import MEETING_STATUSES from '@/enums/meetingStatuses'

export default {
  name: 'PageLevel10',
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  components: {
    AppErrorCard,
    AppDialogJSONImportExport,
    AppTeamworkSync,
    MeetingToolbar,
    MeetingDialogEditName,
    EOSLevel10
  },
  props: {
    showAuthLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      loadState: 'loading',
      level10Id: null,
      showImportExportModal: false,
      showEditDialog: false,
      savingLevel10Name: false,
      isUpdatingLevel10JSON: false,
      level10JSON: '',
      level10Object: {
        meta: {
          twSyncOptions: {}
        },
        data: {}
      },
      anonymousUser: {
        displayName: 'Guest User',
        photoURL: '',
        email: 'anonymous@instantagencytools.com',
        handle: '',
        isAnonymous: true
      },
      twProjects: [],
      twTasklists: [],
      showDialogConfirmDelete: false,
      lastUpdatedMinsBefore: 0,
      isExportingToNotebook: false,
      appMessage: '',
      appMessageType: ''
    }
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    isLoading () {
      return this.loadState === 'loading'
    },
    hasError () {
      return this.loadState === 'error'
    },
    hasContent () {
      return !this.isLoading && !this.hasError
    },
    pageTitle () {
      let title = 'Weekly Meeting'
      if (this.level10Object.meta.name) title += ` - ${this.level10Object.meta.name}`
      return title
    },
    previousMeetingId () {
      return this.level10Object.meta.seguence?.previousId
    },
    nextMeetingId () {
      return this.level10Object.meta.seguence?.nextId
    },
    isEnded () {
      return this.level10Object.data.meetingStatus === MEETING_STATUSES.ENDED
    },
    allowDelete () {
      return !this.isEnded
    },
    allowUpdate () {
      return !this.isEnded
    },
    loggedInUserInfo () {
      // not logged in or is anonymous user
      if (this.loggedInUser === null || (this.loggedInUser && this.loggedInUser.isAnonymous)) {
        const anonymousUser = Object.assign({}, this.anonymousUser)
        // add uid if available
        if (this.loggedInUser?.uid) anonymousUser.uid = this.loggedInUser.uid
        return anonymousUser
      }

      return {
        displayName: this.loggedInUser.displayName || '',
        photoURL: this.loggedInUser.photoURL || '',
        email: this.loggedInUser.email,
        handle: '', // will be created later based on name on teamwork
        uid: this.loggedInUser.uid,
        isAnonymous: false
      }
    },
    enableSyncOption () {
      return this.level10Object.data?.meetingStatus !== MEETING_STATUSES.ENDED
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.loadLevel10DetailsById(to.params.level10Id)
      }
    },
    loggedInUserInfo (info) {
      this.reloadLevel10Details()
    }
  },
  destroyed () {
    // prevent memory leaks
    this.dataManager.destroy()
  },
  methods: {
    ...mapActions(['setMessage', 'exportToNotebook']),
    async loadLevel10DetailsById (level10Id) {
      this.loadState = 'loading'
      this.level10Id = null // will stop overwriting saves while we load

      // It's important we cancel sync data subscriptions so we don't get overlapping data coming in
      // as we reuse this component.
      this.dataManager.cancelSubscriptions()

      const handleError = (error) => {
        this.loadState = 'error'
        this.setMessage({ type: 'error', message: error.message })
        if (FIREBASE_PERMISSION_ERROR_CODES.includes(error.code)) this.removeLevel10RefById(level10Id)
      }

      try {
        this.dataManager.syncObject('level10s', level10Id, async (error, level10Object) => {
          if (error) return handleError(error)

          const syncOptions = level10Object.meta.twSyncOptions || {}
          const hasAccessTokenChanged = syncOptions.accessToken &&
            this.level10Object.meta.twSyncOptions.accessToken !== syncOptions.accessToken &&
            this.isLoading
          const showSyncOptions = syncOptions.accessToken &&
            (!syncOptions.projectId || !syncOptions.tasklistId) &&
            this.isLoading
          const isDomainInfoMissing = syncOptions.accessToken && (!syncOptions.domain || !syncOptions.siteName) && this.isLoading

          // Perform sanity check on meta and data
          this.sanityCheckMeta(level10Object.meta)
          this.sanityCheckData(level10Object.data)

          // Assign valaues
          this.level10Id = level10Id
          this.level10Object = Object.assign({}, level10Object)

          // few users may leave meeting without stopping the timer
          // when they visits meeting again check it's not too long
          //
          if (this.level10Object.data.meetingStatus === MEETING_STATUSES.STARTED &&
            this.level10Object.data.meetingIsRunning && this.isLoading) {
            const lastUpdate = this.$helpers.getUnixTimestamp() - (this.level10Object.meta.lastUpdate.timestamp / 1000)
            this.lastUpdatedMinsBefore = Math.floor(lastUpdate / 60)
          }

          this.loadState = 'content'

          await this.$nextTick()
          // fetch synced projects, tasklist and get attendee handles
          if (hasAccessTokenChanged) this.$refs.appTWSync.fetchSyncedOptions()

          // fetch and add URL to sync options so we can have link to TW task
          // we later added siteName as we are allowing multiple TW accounts
          // add if any  of the option is missing
          if (isDomainInfoMissing) this.$refs.appTWSync.fetchAccountDetails()

          // has accessToekn but not selected any options (created meeting from existing accessToken)
          if (showSyncOptions && this.level10Object.data.meetingStatus !== MEETING_STATUSES.ENDED) {
            this.$refs.appTWSync.showDialogSyncOptions = true
          }

          // Force user to edit the name on afte rany change if it's default and meeting is not running
          if (level10Object.meta.name.indexOf('My Team') === 0) this.showLevel10NameEditForm()
        })
      } catch (error) {
        handleError(error)
      }
    },
    reloadLevel10Details () {
      this.loadLevel10DetailsById(this.$route.params.level10Id)
    },
    sanityCheckMeta (meta) {
      if (typeof meta.meetingDateYYYYMMDD !== 'string') {
        meta.meetingDateYYYYMMDD = this.dataManager.getDateInYYYYMMDD()
      }
      if (!meta.twSyncOptions) meta.twSyncOptions = {}
      return meta
    },
    sanityCheckData (data) {
      if (typeof data.facilitator !== 'string') { data.facilitator = '' }
      if (typeof data.meetingIsRunning !== 'boolean') { data.meetingIsRunning = false }
      if (typeof data.plan !== 'object') data.plan = {}
      if (typeof data.conclude !== 'object') data.conclude = {}
      if (typeof data.conclude.messageToShare !== 'string') { data.conclude.messageToShare = '' }
      if (!Array.isArray(data.conclude.extraTodos)) { data.conclude.extraTodos = [] }
      if (!Array.isArray(data.conclude.meetingRatings)) { data.conclude.meetingRatings = [] }
      data.plan = {
        imageURL: '',
        notes: '',
        carriedOver: true,
        hasFacilitator: true,
        hiddenSections: [],
        ...data.plan
      }

      // I had a silly misspelling (seque should be segue), this fixes it on load
      if (typeof data.seque === 'object') {
        // move to segue only if it doesn't exist
        if (typeof data.segue !== 'object') data.segue = data.seque
        delete data.seque
      }

      // We had meetingHasStarted & meetingHasEnded previously
      // Now we have single attribute called 'meetingStatus = planned/started/ended'
      // Provide backward compatibility for old data
      // If condition below is to fix an issue caused by data inconsistency
      // Some meeting having meetingHasStarted and/or meetingHasEnded attribute present in data
      // Although it should not be there (may be because of async update by multiple users?)
      // To fix that issue we should not consider those attribute and need to delete them if meetingStatus is there
      //
      if (data.meetingStatus) {
        delete data.meetingHasStarted
        delete data.meetingHasEnded
      } else {
        data.meetingStatus = MEETING_STATUSES.PLANNED
        if (data.meetingHasStarted !== undefined) {
          data.meetingStatus = data.meetingHasStarted ? MEETING_STATUSES.STARTED : data.meetingStatus
          delete data.meetingHasStarted
        }
        if (data.meetingHasEnded !== undefined) {
          data.meetingStatus = data.meetingHasEnded ? MEETING_STATUSES.ENDED : data.meetingStatus
          delete data.meetingHasEnded
        }
      }

      if (!Array.isArray(data.attendees)) data.attendees = []
      data.attendees.forEach(function (attendee) {
        if (typeof attendee.photoURL !== 'string') attendee.photoURL = ''
      })

      // Fix: https://digitalcrew.teamwork.com/#/tasks/19044086
      if (data.todos && data.todos.todos) {
        const hasNullTodos = data.todos.todos.some(todo => todo === null)
        if (hasNullTodos) {
          data.todos.todos = data.todos.todos.filter(todo => todo !== null)
          this.dataManager.updateObject('level10s', this.level10Id, {
            'data.todos': data.todos
          })
        }
      }

      return data
    },
    async removeLevel10RefById (level10Id) {
      try {
        await this.dataManager.updateUserReferenceToObject('level10s', level10Id)
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error removing meeting ref: ${error.message}` })
      }
    },
    async updateLevel10Date (date) {
      try {
        this.$set(this.level10Object.meta, 'meetingDateYYYYMMDD', date)
        await this.dataManager.updateObject('level10s', this.level10Id, {
          'meta.meetingDateYYYYMMDD': date
        })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating weekly meeting date: ${error.message}` })
      }
    },
    toggleLevel10Privacy () {
      if (this.loggedInUserInfo.isAnonymous) {
        return this.setMessage({
          type: 'error',
          message: 'Please sign in to make this meeting private.'
        })
      }

      const currentVisibility = this.level10Object.meta.visibility
      const visibility = currentVisibility === 'private' ? 'public' : 'private'

      this.$set(this.level10Object.meta, 'visibility', visibility)
      this.dataManager.updateObject('level10s', this.level10Id, {
        'meta.visibility': this.level10Object.meta.visibility
      })

      this.$analytics('meeting_changed_privacy', { privacy: this.level10Object.meta.visibility })
    },
    async updatePermissions ({ domains, usersMeta, users, redirect }) {
      try {
        // determine user is present in meeting
        const isPresent = (user) => {
          if (user.email === this.loggedInUser.email) return true
          const attendee = this.level10Object.data.attendees.find(u => u.email === user.email)
          return attendee && attendee.isPresent !== undefined ? attendee.isPresent : true
        }
        users = users.map(user => ({ ...user, isPresent: isPresent(user) }))

        this.$set(this.level10Object.meta, 'permissions', { domains, ...usersMeta })
        this.$set(this.level10Object.data, 'attendees', users)
        this.dataManager.updateObject('level10s', this.level10Id, {
          'meta.permissions': this.level10Object.meta.permissions,
          'data.attendees': this.level10Object.data.attendees
        })

        if (!redirect) return
        // user leaving the meeting
        this.$router.push({
          name: 'home',
          params: {
            removeEntity: 'level10s',
            removeId: this.level10Id
          }
        })
      } catch (error) {
        this.setMessage({
          type: 'error',
          message: `Error syncing attendees: ${error.message}`
        })
      }
    },
    showLevel10NameEditForm () {
      if (!this.allowUpdate) return
      this.showEditDialog = true
    },
    async saveLevel10Name (name) {
      // Validate
      if (name.length === 0) {
        return this.setMessage({ type: 'error', message: 'Fill in the fields' })
      }

      // Direct firebase update
      try {
        this.savingLevel10Name = true
        await this.dataManager.updateObject('level10s', this.level10Id, {
          'meta.name': name
        })
        this.$set(this.level10Object.meta, 'name', name)
        this.closeLevel10NameEditForm()
        // We need to also update the name in the list
        this.dataManager.updateObjectNameInList(
          'level10s',
          this.level10Id,
          this.level10Object.meta.name
        )
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error saving details: ${error.message}` })
      } finally {
        this.savingLevel10Name = false
      }
    },
    closeLevel10NameEditForm () {
      this.showEditDialog = false
    },
    async deleteLevel10 () {
      try {
        this.showDialogConfirmDelete = false
        this.loadState = 'loading'
        this.dataManager.cancelSubscriptions()
        await this.dataManager.deleteObject('level10s', this.level10Id)
        this.$router.push({ name: 'home' })
      } catch (error) {
        this.loadState = 'content'
        this.setMessage({
          type: 'error',
          message: `Error deleting weekly meeting: ${error.message}`
        })
      }
    },
    openImportExportModal () {
      this.level10JSON = JSON.stringify(this.$refs.eosLevel10.l10Data, null, 4)
      this.showImportExportModal = true
    },
    async updateLevel10JSON (json) {
      try {
        this.isUpdatingLevel10JSON = true
        const data = this.sanityCheckData(JSON.parse(json))
        await this.dataManager.updateObject('level10s', this.level10Id, {
          data: data
        })
        this.closeImportExportModal()
        this.setMessage({ type: 'success', message: 'Data updated' })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating data: ${error.message}` })
      } finally {
        this.isUpdatingLevel10JSON = false
      }
    },
    closeImportExportModal () {
      this.showImportExportModal = false
    },
    async tryExport () {
      try {
        if (!this.level10Object.meta.twSyncOptions.notebookId) return
        this.isExportingToNotebook = true
        await this.exportToNotebook(this.$route.params.level10Id)
      } finally {
        this.isExportingToNotebook = false
      }
    }
  },
  async mounted () {
    const object = await this.dataManager.getAppInfo()
    if (object.exists) {
      const lastWarningDate = localStorage.getItem('iatLastMessageDate')
      const { message } = object.data()
      const { text, type, date } = message || {}
      if (lastWarningDate !== date && text && type) {
        this.appMessage = text
        this.appMessageType = type
      }
      localStorage.setItem('iatLastMessageDate', date)
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-l10__content {
  height: calc(100vh - 96px);
  overflow: hidden;
}
</style>
