<template>
  <v-stepper
    :value="currentStep"
    vertical
    class="elevation-0 d-block eos-meeting__stepper"
  >
    <template v-for="(step, index) in steps">
      <v-stepper-step
        :key="`stepper-step-${index}`"
        :step="index + 1"
        :complete="currentStep > index + 1"
        class="pt-3 cursor-pointer eos-meeting__step"
        @click="$emit('select-step', index)"
      >
        <span v-html="step.shortName"></span>
        <small class="mt-1">{{ step.sideSubText }}</small>
      </v-stepper-step>

      <v-stepper-content :key="`stepper-content-${index}`" :step="index + 1">
        <v-container fluid ma-0 pa-0 pb-2>
          <v-layout row align-center>
            <v-flex v-show="step.timeInMins" text-md-center>
              <v-progress-circular
                :rotate="-90"
                :size="90"
                :width="10"
                :value="progress"
                :color="color"
              >
                <small>{{ step.timeInMins }} mins</small>
              </v-progress-circular>
            </v-flex>
            <v-flex xs3 class="ml-auto">
              <v-tooltip v-if="index !== steps.length - 1" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    :color="progress >= 95 ? 'red' : undefined"
                    @click="$emit('select-step', index + 1)"
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </template>
                <span>Next</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
    </template>
  </v-stepper>
</template>

<script>
export default {
  name: 'MeetingStepper',
  props: {
    steps: {
      type: Array,
      default: () => ([])
    },
    currentStep: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: 'primary'
    },
    progress: {
      type: [String, Number],
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-meeting__stepper {
  height: calc(100vh - 205px);
  overflow-y: auto;
}

.eos-meeting__step:hover {
  background: #f8f8f8;
  color: #333;
}
</style>
