<template>
  <v-toolbar dense flat class="eos-meeting__toolbar">
    <v-toolbar-title
      v-if="name"
      class="mr-2"
      :class="{ 'cursor-pointer': allowUpdate }"
      @click="$emit('edit-name')"
    >
      {{ name }}
    </v-toolbar-title>

    <v-tooltip v-if="previousId || nextId" bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          x-small
          depressed
          class="ml-4"
          color="#777"
          :disabled="!previousId"
          :to="{
            name: 'level10',
            params: { level10Id: previousId }
          }"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <span>Previous</span>
    </v-tooltip>

    <AppDatePicker
      title="Meeting Date"
      :date="date"
      :disabled="!allowUpdate"
      @change="$emit('change-date', $event)"
    />

    <v-tooltip v-if="nextId" bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          x-small
          depressed
          color="#777"
          :to="{
            name: 'level10',
            params: { level10Id: nextId }
          }"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <span>Next</span>
    </v-tooltip>

    <v-spacer />

    <AppVisibilityBtn
      entity="weekly meeting"
      :visibility="visibility"
      @click="$emit('toggle-privacy')"
    />

    <v-menu bottom left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item v-if="allowUpdate" @click="$emit('edit-name')">
          <v-list-item-title>Rename</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="allowDelete" @click="$emit('delete')">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('import-export')">
          <v-list-item-title>Import/Export Data</v-list-item-title>
        </v-list-item>
        <AppSyncOptions
          v-if="allowSync"
          :tw-sync-options="twSyncOptions"
          @show-sync-options="$emit('show-sync-options')"
          @initialize-tw-oauth-process="$emit('initialize-tw-oauth-process')"
          @save-sync-options="$emit('save-sync-options', $event)"
        />
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import AppDatePicker from '@/components/shared/datepickers/AppDatePicker'
import AppVisibilityBtn from '@/components/shared/visibility/AppVisibilityBtn'
import AppSyncOptions from '@/components/shared/sync/AppSyncOptions'

export default {
  name: 'MeetingToolbar',
  components: {
    AppDatePicker,
    AppVisibilityBtn,
    AppSyncOptions
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: null
    },
    previousId: {
      type: String,
      default: ''
    },
    nextId: {
      type: String,
      default: ''
    },
    visibility: {
      type: String,
      default: ''
    },
    allowUpdate: {
      type: Boolean,
      default: true
    },
    allowDelete: {
      type: Boolean,
      default: true
    },
    allowSync: {
      type: Boolean,
      default: false
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-meeting__toolbar {
  border-bottom: 1px solid #eee;
}
</style>
