<template>
  <div>
    <v-menu v-model="isOpen" open-on-hover offset-x max-width="500px" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon x-small class="ml-1" v-on="on" v-bind="attrs" @click="editStepGuide">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </template>

      <MeetingMarkdownText
        class="pl-5 py-3"
        icon-class="mt-n1 mr-n2"
        edit-btn-title="Edit Step Guide"
        :text="text"
        :is-editable="isEditable"
        @edit="editStepGuide"
      />
    </v-menu>

    <MeetingDialogMarkdownEditor
      v-model="showDialog"
      title="Edit Step Guide"
      label="Guide Text"
      :text="text"
      :show-loader="isUpdatingGuide"
      @save="$emit('save', $event)"
    />
  </div>
</template>

<script>
import MeetingMarkdownText from '@/components/meeting/MeetingMarkdownText'
import MeetingDialogMarkdownEditor from '@/components/meeting/dialogs/MeetingDialogMarkdownEditor'

export default {
  name: 'MeetingStepGuide',
  components: {
    MeetingMarkdownText,
    MeetingDialogMarkdownEditor
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isUpdatingGuide: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      showDialog: false
    }
  },
  methods: {
    editStepGuide () {
      this.isOpen = false
      this.openEditDialog()
    },
    openEditDialog () {
      this.showDialog = true
    },
    closeEditDialog () {
      this.showDialog = false
    }
  }
}
</script>
