<template>
  <v-card flat color="white" class="position-relative">
    <vue-markdown
      :source="text"
      :anchor-attributes="{
        target: '_blank'
      }"
      :class="{ 'mr-10': isEditable }"
    />

    <v-tooltip v-if="isEditable" bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          small
          absolute
          top
          right
          :class="iconClass"
          @click.prevent="$emit('edit')"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>{{ editBtnTitle }}</span>
    </v-tooltip>
  </v-card>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'MeetingMarkdownText',
  components: { VueMarkdown },
  props: {
    text: {
      type: String,
      default: ''
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    editBtnTitle: {
      type: String,
      default: 'Edit'
    },
    iconClass: {
      type: [String, Array],
      default: 'mt-n4 mr-n3'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep p:last-child {
  margin-bottom: 0px !important;
}
</style>
